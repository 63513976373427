//import Components
import { Link } from 'react-router-dom';
import PropTypes, {func} from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAppState} from "../../context/global";


Cover.propTypes = {
    size: PropTypes.number,
    cols: PropTypes.number,
    small: PropTypes.number,
    medium: PropTypes.number,
    large: PropTypes.number,
};

Cover.defaultProps = {
    size: 100
};

//----------------[Cover Element for Releases List]---------------------//
export default function Cover({item, size, small, medium, large, hoverEffect,...props}) {
    if (item) {
        const generatedHref = item.title.replace(/ /g, "").toLowerCase();


        //-----------[HTML]----------------//
        //content to render on page
        return (
            <>
                <div className={`${hoverEffect ? "cover--scale" : "cover"}`} >
                    {/*cover image*/}
                    <div className={`cover_image`}>
                        <img className={"cover-img"} src={`/media/images${item.cover}`} alt={`cover of ${item.title}`}/>
                    </div>

                    {/*Cover information*/}
                    <div className={`cover_info`}>
                        <div className={`cover_top`}>
                            <div className={`cover_hoverContainer`}>
                                <Link className={`cover_link`} to={`/releases/${generatedHref}`}/>{/*Link element in
                                 background of cover*/}

                                {/*platform links (top right)*/}
                                <div className={`cover_platformsContainer`}>
                                    <a href={item.spotify} target={"_blank "} rel={"noopener noreferrer"}>
                                        <FontAwesomeIcon className={`cover-platform spotify link`} icon={`fa-brands fa-spotify`} />
                                    </a>
                                    <a href={item.youtube} target={"_blank"} rel={"noopener noreferrer"}>
                                        <FontAwesomeIcon className={`cover-platform youtube link`} icon={`fa-brands fa-youtube`} />
                                    </a>
                                </div>

                                {/*Read more button*/}
                                <Link className={`cover_readMoreContainer`} to={`/releases/${generatedHref}`}>
                                    <span className={`cover-readMore`}>
                                        Click to read more
                                    </span>
                                    <div className="coverBtn"/>
                                    <div className="coverBtn-bottom"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`cover_bottom`}>

                </div>
            </>
        )
    } else return <div className={`cover--empty`}/>

}