//import styles
import './styles/styles.scss'

//importing next components
import {AppStateProvider} from "./context/global";
import { BrowserRouter} from "react-router-dom";
import App from "./App";
import reactDOM from "react-dom";
import HttpsRedirect from 'react-https-redirect';

//font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {Helmet} from "react-helmet";
import {useEffect} from "react";

library.add(fab, fas);


//here only stuff inside <Helmet>...</Helmet> is editable, as this is the routing for the page

reactDOM.render(
    <>
        <Helmet>
            <title key="title">PGN Music</title>
        </Helmet>
        <HttpsRedirect>
            <AppStateProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </AppStateProvider>
        </HttpsRedirect>
    </>,
    document.getElementById('root')
)
