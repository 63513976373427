//import Components
import Cover from "../Cover";
import Slider from "react-slick";
import {coverListSettings} from "./settings";
import PropTypes, {object} from "prop-types";

CoverList.propTypes = {
    items: PropTypes.arrayOf(object),
    className: PropTypes.string,
    label: PropTypes.string,
}
CoverList.defaultProps = {

}


//----------------[Cover List component]---------------------//
//this page except the carouselResponsive values should not be edited

export default function CoverList ({items, label, className, limit,...props}) {
    //gets the settings from the settings file (from function coverListSettings);
    const {carouselSettings, carouselResponsive} = coverListSettings(items);

    let itemList = items;
    if (limit) {
        itemList = items.slice(0, limit);
    }


    //checks we got items in the list
    if (items.length > 0) {

        //-----------[HTML]----------------//
        //content to render on page
        return (
            <>
                <div className={`releaseCategory`}>
                    <div className={`release_label`}>
                        <h3 className={`releases-label`}>{label.toUpperCase()}</h3>
                    </div>
                    <Slider className={`release_list ${className}`} responsive={carouselResponsive} {...carouselSettings}>
                        {itemList.map((item, i) => {
                            return (
                                <Cover item={item} hoverEffect={true} key={`r-${label.charAt(0)}-${i}`}/>
                            )
                        })}
                    </Slider>
                </div>
            </>
        )
    } else return null;
}