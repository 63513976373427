import { useLocation, matchPath } from "react-router"
import {lazy, useEffect} from "react"
const About = import("../pages/about");
const Releases = import("../pages/releases");
const ReadMore = import("../pages/releases/ReadMore");

const WAIT_MS = 2000

const prefetchMap = [
    {
        path: "/",
        prefetchComponents: [About, Releases, ReadMore],
    },
    {
        path: "/releases",
        prefetchComponents: [ReadMore]
    }
]

export function prefetchPages(location) {
    // Listen to the current “location” or url


    const prefetchConf = prefetchMap.find(({ path }) =>
        location.pathname === path
    )

    if (prefetchConf) {
        // Prefetch after a delay so we don’t slow down
        // more important network requests.
        const id = setTimeout(() => {
            prefetchConf.prefetchComponents.forEach(component => {
                //console.log("fetched")
                try {
                    // This calls the dynamic import() statement,
                    // which triggers the network request.
                    component()
                } catch {
                    // Not a big deal. Probably trying to fetch
                    // an old version of the chunk.
                }
            })
        }, WAIT_MS)

        // Effect cleanup
        return () => {
            clearTimeout(id)
        }
    }
}