//import Components
import { useLocation } from "react-router-dom"
import Nav from "../components/Navigation/Nav";
import Footer from "../components/Footer/Footer";
import {useAppState} from "../context/global";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useRef} from "react";
import {scrollToTop} from "../functions/global";
import {useFixToTopBtn} from "../functions/Layout";
import {prefetchPages} from "../functions/prefetch";


//function to set position of to top button





//----------------[App - Layout]---------------------//

export default function Layout({...props}) {
    const [appState, setAppState] = useAppState();
    const data = appState.data;

    const router = useLocation();
    //useCheckRedirect(router);
    let page = router.pathname.split(/[/ ]+/).pop();
    if (!page) {
        page = "home";
    }

    //scroll to top on page redirect
    useEffect(() => {
        scrollToTop();
        prefetchPages(router);
    }, [router])

    //scroll to top of page on page change
    const elementRef = useRef({});
    useFixToTopBtn(elementRef);




    //-----------[HTML]----------------//
    //content to render on page
    return (
        <>
            <Nav/>
            <main id={page} className={`page`}>
                <div id={`pageContent`} className={`page_container`}>
                    {props.children}
                </div>
            </main>
            <div className={`toTop-btn link--hover`} onClick={scrollToTop} ref={el => elementRef.current.toTopBtn = el}>
                <FontAwesomeIcon className={`toTop-icon`} icon="fa-solid fa-angles-up"/>
            </div>
            <Footer forwardRef={el => elementRef.current.footer = el}/>
        </>
    )
}