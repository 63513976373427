//import Next Components
import React from 'react';
import FCarousel from "../../components/Carousel/Hero";
import PlatformList from "../../components/Platforms/Lists/PlatformList";
import {useAppState} from "../../context/global";
import {Helmet} from "react-helmet";
import CoverList from "../../components/Releases/Lists/CoverList";

//----------------[Page - Homepage]---------------------//

export default function Home({...props}) {
    const [appState, setAppState] = useAppState();
    const data = appState.data;


    //-----------[HTML]----------------//
    //content to render on page
    return (
        <>
            <Helmet>
                <title key="title">PGN Music | Copyright Free Music</title>
                <meta name={"keywords"} key={"base-keywords"} content={appState.keywords + "Homepage, Landing page, overview, latest, new"}/>
            </Helmet>
            <section className={`home_carousel page_section--fullWidth hero`}>
                <FCarousel items={data.hero} className={`home_heroCarousel heroCarousel`}/>
            </section>
            <section className={`home_releases page_section`}>
                <CoverList className={`home_latest`} items={data.releases} label={"Latest Releases"} limit={6}/>
            </section>
            <section className={`home_platforms page_section`}>
                <PlatformList items={data.platforms}/>
            </section>
        </>
    )
}
