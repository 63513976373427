//import Components

import PropTypes from "prop-types";
import {useEffect} from "react";
import {staticMetaKeyword} from "../App";

//function for sorting through the list of releases
function sortReleases(data) {/*function to sort releases by release date*/

    data.releases.sort((a,b) => {
        return new Date(a.releaseDate) > new Date(b.releaseDate);
    });
    return data;
}


export default function useFetchData (appState, setAppState, setError) {
    useEffect(() => {
        //console.log(fetchData());
        function fetchData() {
            fetch("/data.json")
                .then(response => {
                    //console.log(response);
                    return response.json();
                })
                .then(fetchData => {
                    //console.log(fetchData);
                    const data = fetchData;
                    setAppState({
                        ...appState,
                        data: {
                            ...data,
                        },
                        keywords: staticMetaKeyword + `${data.categories.map(category => ` ${category.label}`)}, `
                    });
                    setError(false);
                    return data;
                })
                .catch(error => {
                    console.error("Error getting content, slacker has done something bad.", error);
                    setError(true);
                })
        }
        fetchData();
        //setTimeout(fetchData, 30000);
    }, []);
}