//import Components

import PropTypes from "prop-types";
import ReactLoading from "react-loading";
LoadingScreen.propTypes = {

}

LoadingScreen.defaultProps = {

}

export default function LoadingScreen ({...props}) {
    return (
        <div className="App" style={{background: "#000000", minHeight: "100vh"}}>
            <div className="page_loading" style={{width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                <ReactLoading delay={500} className={`page-loading`} type={"spinningBubbles"} color={"#efefef"} height={'10%'} width={'10%'}/>
            </div>
        </div>
    )
}