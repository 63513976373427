import {SampleNextArrow, SamplePrevArrow} from "../../Carousel/arrows";


//----------------[Settings for the category displaying]---------------------//
//this is where you can edit the settings for how the category items are listed

export function coverListSettings(items) {
    const carouselSettings = {
        slidesToScroll: 1, //sets fallback default value for how many slides to show
        infinite: false, //sets fallback default value for if the slides should have infinite scroll
        nextArrow: <SampleNextArrow />,//custom arrows for slides can be found in "..../carousel/arrows.js"
        prevArrow: <SamplePrevArrow />,//custom arrows for slides can be found in "..../carousel/arrows.js"
        lazyLoad: true, //do not remove this setting, this helps page load quicker by only loading in elements when
        // in view
    }
    const carouselResponsive = [
        {
            breakpoint: 375, //maximum width before this no longer applies
            settings: {
                infinite: items.length > 1, //this value should always be same as slides to show
                slidesToShow: 1 //number of slides to show for this view config (from previous/0 -> breakpoint)
            }
        },
        {
            breakpoint: 520, //maximum width before this no longer applies
            settings: {
                infinite: items.length > 2, //this value should always be same as slides to show
                slidesToShow: 2 //number of slides to show for this view config (from previous/0 -> breakpoint)
            }
        },
        {
            breakpoint: 750, //maximum width before this no longer applies
            settings: {
                infinite: items.length > 2, //this value should always be same as slides to show
                slidesToShow: 2 //number of slides to show for this view config (from previous/0 -> breakpoint)
            }
        },
        {
            breakpoint: 1300, //maximum width before this no longer applies
            settings: {
                infinite: items.length > 3, //this value should always be same as slides to show
                slidesToShow: 3 //number of slides to show for this view config (from previous/0 -> breakpoint)
            }
        },
        {
            breakpoint: 999999, //maximum width before this no longer applies
            settings: {
                infinite: items.length > 4, //this value should always be same as slides to show
                slidesToShow: 4 //number of slides to show for this view config (from previous/0 -> breakpoint)
            }
        }

    ]

    return {carouselSettings, carouselResponsive};
}