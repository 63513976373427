import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left slick-customArrow slick-customPrev" onClick={onClick}>
            <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
        </div>
    );
}

export function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right slick-customArrow slick-customNext" onClick={onClick}>
            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
        </div>
    );
}