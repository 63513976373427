//import Components
import {Link, NavLink} from 'react-router-dom';
import SocialList from "../Social/Lists/SocialList";
import {useAppState} from "../../context/global";


export default function Footer({forwardRef, ...props}) {
    const [appState, setAppState] = useAppState();
    const data = appState.data;

    return (
        <>
            <footer className={`footer`} ref={forwardRef}>
                <div className={`footer row`}>
                    <div className={`footer_left col-s-12 col-m-6 col-l-3`}>
                        <div className={`footer_navigation`}>
                            <div>
                                <NavLink className={`footer-link`} to={`/`}>HOME</NavLink>
                            </div>
                            <div>
                                <NavLink className={`footer-link`} to={`/releases`}>RELEASES</NavLink>
                            </div>
                            <div>
                                <NavLink className={`footer-link`} to={`/about`}>ABOUT</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className={`footer_center col-s-12 col-m-12 col-l-6`}>
                        <div className={`footer_socials`}>
                            <SocialList className={`footer_socials`} cols={`col-s-6 col-m-4 col-l-2`} socials={data.socials}/>
                        </div>
                    </div>
                    <div className={`footer_right col-s-12 col-m-6 col-l-3`}>
                        <div className={`footer_contact`}>
                            <span className={`footer_contactHeading`}>Contact Information</span>
                            <div>
                                <a className={`footer_email footer-item`} href={`mailto:support@paradoxgaming.net`}>
                                    support@paradoxgaming.net
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>

    )
}
