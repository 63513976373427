//import Components
import {useState, lazy, Suspense, useEffect} from "react"
import {Routes, Route, useLocation} from "react-router-dom";
import {useAppState} from "./context/global";
import Layout from "./Layouts/MainLayout";
import Home from "./pages/home";
import {Helmet} from "react-helmet";
import useFetchData from "./api/fetch";
import LoadingScreen from "./components/Loadingscreen/Loadingscreen";
const About = lazy(() => import("./pages/about"));
const Releases = lazy(() => import("./pages/releases"));
const ReadMore = lazy(() => import("./pages/releases/ReadMore"));
const PageNotFound = lazy(() => import('./pages/errors/404'));
const BadRequest = lazy(() => import("./pages/errors/400"));


//--------------------------[!Note]----------------------------------//
//this page should never be needed to be updated unless adding pages (ask Zettrex)
//if you want to add content like SEO or meta content add in HELMET(works like <head></head>) element just like good old HTML

//this is the pages static keywords, meaning what should be on all pages
export const staticMetaKeyword = `Jahlon, PGN Music, Paradox Gaming Network Music, Jahlon Music, DMCA Free Music, No Copyright Sound, No Copyright Music, Copyright Free Music, Free to Use Music, Copyright Free Music for Streamers, pgnmusic.com, free music, twitch, youtube, spotify, imusic, soundcloud, music, songs, community, `


/*React app component*/
export default function App ({...props}) {
    const [appState, setAppState] = useAppState();
    const [error400, setError400] = useState(false);

    //This gets the data for the webpage and adds them to a variable to be used to fill the page
    useFetchData(appState, setAppState, setError400);





    /*Makes sure we have data before we load the page*/
    if (appState.data) {


        //-----------[HTML]----------------//
        //content to render on page
        return (
            <>
                <div className="App">
                    <Helmet>
                        <meta name={`keywords`} content={appState.keywords} key={`base-keywords`}/>
                    </Helmet>
                    <Layout>
                        <Suspense fallback={<LoadingScreen/>}>
                            <Routes>
                                <Route path="/" exact element={<Home/>}/>
                                <Route path="/about" exact element={<About/>}/>
                                <Route path="/releases" exact element={<Releases/>}/>
                                {/*<Route path="/admin" exact element={<Admin/>}/>*/}{/*This page is not active untill I
                             do the final database*/}
                                <Route path="/releases/:id" element={<ReadMore/>}/>
                                <Route path="/400" element={<BadRequest/>}/>
                                <Route path="*" element={<PageNotFound/>} />
                            </Routes>
                        </Suspense>
                    </Layout>
                </div>
            </>
        )
    } else if (error400) {
        //If we hit an error getting data from Database, we will display the BadRequest page
        return <BadRequest/>;
    } else {
        /*If we still are loading in data, we will display a loading component*/
        return <LoadingScreen/>;
    }

}