//import Components
import {useEffect} from "react";
import $ from "jquery";
import {debounce} from "./global";


export function useFixToTopBtn(elementRef) {
    useEffect(() => {
        const btn = elementRef.current.toTopBtn;
        const footer = elementRef.current.footer;
        function checkScroll() {
            if (((window.scrollY + window.innerHeight) > (window.innerHeight * 1.20)) && ((window.scrollY + window.innerHeight) < ($(document).height() - (footer.offsetHeight -90)))) {
                btn.style.bottom = "-400px"
                btn.className = "toTop-btn--fixed";
                btn.style.bottom = "";
            } else {
                btn.className = "toTop-btn";
                btn.style.bottom = `${footer.offsetHeight}px`;
                btn.style.transform = "";
            }

        }

        window.addEventListener("scroll", debounce(checkScroll, 0));
        // Call handler right away so state gets updated with initial window size
        checkScroll();
        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", debounce(checkScroll, 0));
    }, [])

}
