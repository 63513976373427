//import Components
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

Social.propTypes = {

}

Social.defaultProps = {

}

export default function Social ({item, cols, ...props}) {


    //-----------[HTML]----------------//
    //content to render on page
    return (
        <>
            <a className={`social_iconContainer link--hover ${cols}`} href={item.link} target={`_blank`} rel={`noopener noreferrer`}>
                {item.customIcon ? /*if a custom item we create an icon with the custom image supplied, if not we
                 get one from font awesome.*/
                    <img className={`social-icon link`} src={`/media/images${item.customIcon}`} alt={`${item.customIcon}'s Logo`}/>
                    :
                    <FontAwesomeIcon className={`social-icon link`} icon={`fa-brands fa-${item.name.toLowerCase()}`}/>
                }
            </a>
        </>
    )
}